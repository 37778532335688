var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        {
          ref: "xTable1",
          attrs: { "row-key": "", "row-id": "id", data: _vm.getinvoiceData },
        },
        [
          _c("vxe-table-column", {
            attrs: { title: "开票方名称", field: "salerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "开票方税号", field: "salerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "购货方名称", field: "sallerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "购货方纳税人识别号", field: "sallerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "收款方企业名称", field: "buyerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "收款方企业税号", field: "buyerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票号码", field: "invoiceNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "价税合计总额", field: "totalAmount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "总金额(不含税)", field: "amount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "总税额", field: "taxAmount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "税率", field: "taxRate" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票流水号", field: "serialNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票日期", field: "invoiceDate" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.closeForm },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }