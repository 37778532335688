<!--
 * @Autor: yzr
 * @Date: 2021-12-26 17:29:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-12-29 17:18:47
 * @Description: 发票上传主体表格
-->
<template>
  <div class="box">
    <iframe :src="url" frameborder="1" height="100%" width="100%"></iframe>
  </div>
</template>

<script>
import request from '../../../../utils/request';

export default {
  components: {},
  props: {
    getinvoiceSerialNos: String,
    formConfig: Object,
  },

  data() {
    return {
      url: '',
      invoiceSerialNos: '',
      pwyWebsocket: null,
    };
  },

  async created() {
    this.getUrl();
  },
  mounted() {},
  destroyed() {
    this.pwyWebsocket.close();
  },
  methods: {
    // 获取第三方地址
    async getUrl() {
      let num = '';
      for (let i = 0; i < 3; i += 1) {
        num += Math.floor(Math.random() * 10);
      }
      const timestamp = new Date().getTime();
      const reqid = timestamp + num;
      let websocketObj = {};
      // 获取获取linkKey、userKey及参数
      await request.post('/tpm/tpmscaninvoice/getUserKey', { bussinesId: this.formConfig.bussinesId, processInstanceId: this.formConfig.processInstanceId }).then((res) => {
        if (res.code === 200) {
          websocketObj = res.result;
        }
      });
      this.url = `${websocketObj.urlPrefix}/m4-web/fpzs/index?userKey=${websocketObj.userKey}&linkKey=${websocketObj.linkKey}&gridParam=1111&sourceType=socket&reqid=${reqid}`;
      console.log(this.url, 999);
      this.pwyWebsocket = new PwyWebSocket({
        env: 'prod', // 正式环境: prod, 测试环境: test
        tin: websocketObj.tin, /// tin为获取userKey时的税号
        eid: websocketObj.eid, // eid为获取userKey时的用户eid
        client_id: websocketObj.clientId, // 发票云授权标识（client_id）
        sign: websocketObj.sign, // 签名规则：MD5(client_id + client_secret + timestamp)
        timestamp: websocketObj.timestamp, // 签名时的时间戳
        name: websocketObj.linkKey, // 连接名称，选择发票前获取的linkKey
        sourceType: 'socket', // 默认socekt, 对于不支持socket的端请设置为polling，java端的轮询参考5
        onOpen() {
          // 连接成功的回调
          console.log(`连接成功${this.name}`);
        },
        onMessage: (msg) => {
          console.log(msg, 'msg333');
          if (!msg.data) {
            this.$message.error('上传发票没有返回发票数据，请检查上传发票是否有问题！');
          } else {
            this.setinvoiceData = msg.data;
            this.$emit('setinvoiceData', this.setinvoiceData);
            const _this = this;
            setTimeout(() => {
              _this.$emit('closeModal');
            }, 300);
          }
          // msg为接收到的消息， 消息格式：{"type":"saveBill", data: {}}
          // 当type为saveBill时, data的数据为导入的发票数据，具体格式参考4.3
          // 当type为saveEntrys时, data的数据为分录调整后的数据，具体格式参考6.4
          // 当type为updateInvoice, data的数据为修改的发票基本信息，具体格式参考14.3
          // 接收到消息后，企业处理好自己业务, 如果要调用查看发票界面（调用6.3接口进行缓存），处理完成关闭窗口即可
        },
        onError(errText, errCode) {
          // 失败时的回调
          console.log(errText, errCode);
        },
        // 关闭连接, 接受到消息之后需要先关闭连接，需要再次使用需要重新创建socket对象
        // pwyWebsocket.close();
      });
    },
  },
};
</script>
<style lang="less" scepod>
.box {
  width: 100%;
  height: 100%;
}
</style>
